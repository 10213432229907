<template>
  <v-select v-bind="$attrs" :items="items" item-value="id" v-on="$listeners">
    <template #item="{item, on}">
      <v-list-item dense v-on="on">
        <v-list-item-avatar
          color="transparent"
          width="26"
          height="26"
          class="my-0"
        >
          <v-img
            :src="item.src"
            width="24"
            height="24"
            max-width="24"
            max-height="24"
            style="filter: invert(100%) brightness(50%) !important;"
          />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template #selection="{item, on}">
      <v-list-item dense class="px-0" v-on="on">
        <v-list-item-avatar
          color="transparent"
          width="26"
          height="26"
          class="my-0"
        >
          <v-img
            :src="item.src"
            width="24"
            height="24"
            max-width="24"
            max-height="24"
            style="filter: invert(100%) brightness(50%) !important;"
          />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-subtitle>{{ item.name }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-select>
</template>

<script>
import { colors } from '@/compositions/map/utils/data';
export default {
  name: 'FormIconSelector',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    return {
      colors
    };
  }
};
</script>

<style lang="sass" scoped>
.grey
  filter: invert(100%) brightness(50%) !important
.select-icon-item
    width: 100%
    height: 100%
    display: flex
    // padding: 6px
    &__text
        margin: auto 0
    // &--selection
    //     padding: 3px
    //     padding-left: 6px
    //     height: 24px
</style>
